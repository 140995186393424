/*
 * @Author: yangzonglong
 * @Date: 2019-08-22 17:30:39
 * @version: v1.0.0
 * @Descripttion: 
 * @LastEditors: yangzonglong
 * @LastEditTime: 2019-11-18 11:41:16
 * @Auditor: 
 */
import { lazy } from 'react';
export { default as demo } from './redux/reducer';

const Demo = lazy(() => import('./Demo'));
const Table = lazy(() => import('./Table'));

export const demoRouter = [
    { name: 'demo', path: '/demo', exact: true, component: Demo },
    { name: 'table', path: '/table', exact: true, component: Table }
]

