import { handleActions } from 'redux-actions';
import { UserCollectionItemProps } from './../models';
import QueryString from 'query-string';
import {
    SET_SUB_PROJECT_PATH,
    SET_MENUS_DISPLAY_STATE,
    ERROR_COMPLETE,
    INIT_COMPLETE,
    SET_BREADCRUMBS,
    SET_USERCOLLECTIONMENUS,
    ADD_USERCOLLECTIONMENU
} from './types';

const query: any = QueryString.parseUrl(window.location.href).query;
const original_url = query.original_url;

export interface BreadcrumbItemProps {
    path: string,
    name: string,
}

interface IState {
    loading: boolean,
    path: string,
    menusDisplayState: boolean,
    breadcrumbs: BreadcrumbItemProps[],
    userCollectionMenus: UserCollectionItemProps[]
}

// 默认数据
const initialState: IState = {
    loading: original_url ? true : false,
    path: original_url || '',
    breadcrumbs: [],
    menusDisplayState: true, // 默认显示
    userCollectionMenus: []
}

export default handleActions<IState>({
    [SET_SUB_PROJECT_PATH]: (state: IState, action: any) => {
        return { ...state, path: action.path, loading: true, breadcrumbs: action.breadcrumbs }
    },
    [SET_MENUS_DISPLAY_STATE]: (state: IState, action: any) => {
        return { ...state, menusDisplayState: action.data }
    },
    [ERROR_COMPLETE]: (state: IState, action: any) => {
        return { ...state, loading: false, path: '' }
    },
    [INIT_COMPLETE]: (state: IState, action: any) => {
        return { ...state, loading: false, menusDisplayState: false }
    },
    // set breadcrumbs
    [SET_BREADCRUMBS]: (state: IState, action: any) => {
        return { ...state, breadcrumbs: action.data }
    },
    // 用户收藏菜单
    [SET_USERCOLLECTIONMENUS]: (state: IState, action: any) => {
        return { ...state, userCollectionMenus: action.data }
    },
    [ADD_USERCOLLECTIONMENU]: (state: IState, action: any) => {
        return {
            ...state,
            userCollectionMenus: action.data.id ?
                state.userCollectionMenus.concat(action.data) : state.userCollectionMenus.concat([])
        }
    }
}, initialState);