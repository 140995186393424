import { handleActions } from 'redux-actions';
import types from './types';

interface IState {
    name: string
}

// 默认数据
const initialState: IState = {
    name: 'yangzonglong'
}

export default handleActions<IState>({
    [types.setName]: (state: IState, action: any) => {
        return { name: action.name };
    },
}, initialState);