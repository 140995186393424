import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import { AppContainer } from 'react-hot-loader';
import * as serviceWorker from './serviceWorker';
import crypto from 'crypto-js';
import './i18n';
import axios from 'axios';

axios.interceptors.request.use((config: any) => {
    config.data = {
        mode: 1,
        data: crypto.AES.encrypt(JSON.stringify(config.data), 'leo_scott_great').toString()
    }
    return config
})

const render = (Component: any) => {
    ReactDOM.render(
        <AppContainer><Component /></AppContainer>,
        document.getElementById('root')
    );
}

render(App);

if (module.hot) {
    module.hot.accept(() => {
        render(App)
    })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
