/*
 * @Author: yangzonglong
 * @Date: 2019-08-23 09:20:42
 * @version: v1.0.0
 * @Descripttion: 
 * @LastEditors: yangzonglong
 * @LastEditTime: 2019-11-20 09:51:09
 * @Auditor: 
 */
import { demo } from '@features/demo';
import { mainFrame } from '@features/mainFrame'

export default {
    demo,
    mainFrame
}