import React, { memo } from 'react';
import cls from './icon.module.scss';

interface IProps {
    icon: string,
    type?: 'error' | 'success',
    className?: any,
    onClick?: () => void
}

const Icon = memo(({ icon, type, className, onClick }: IProps) => {
    return (
        <span
            onClick={onClick}
            className={`${cls.icon} ${onClick ? cls.cursor : null} ${className} ${type ? cls[type] : null}`}>
            {icon}
        </span>
    )
})

export default Icon;