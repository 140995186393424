import { lazy } from 'react'

export const agreementRouter = [{
  name: '用户注册条款(CN)',
  path: '/agreement_cn',
  component: lazy(() => import('./AgreementCN'))
},{
  name: '用户注册条款(EN)',
  path: '/agreement_en',
  component: lazy(() => import('./AgreementEN'))
}]