import React, { useCallback } from 'react';
import { Menu } from 'antd';
import { History } from 'history';
import Icon from './../icon/Icon';
import cls from './welinkinlayout.module.scss';

const MenuItem = Menu.Item,
    SubMenu = Menu.SubMenu;

interface MenuProps {
    path: string, // 路径
    name: string // 路由名称
}

interface IProps {
    menus: Array<{ children: MenuProps[], functionGroupDesc: string, iconb: string }>,
    history: History, // 路由对象
    defaultSelectedKeys: string[]
}

const MenuList = ({ menus, history, defaultSelectedKeys }: IProps) => {

    const onClickMenu = useCallback((item: any) => history.push(item.path), [history]);
    
    return (
        <Menu defaultSelectedKeys={defaultSelectedKeys} defaultOpenKeys={['0']} mode='inline'>
            {menus.map((group, groupIndex) => (
                <SubMenu title={
                    <p className={cls.submenu_icon}>
                        <Icon icon={group.iconb} />
                        <span className={cls.submenu_title}>{group.functionGroupDesc}</span>
                    </p>
                } key={groupIndex}>
                    {group.children.map((item: any) => (
                        <MenuItem
                            onClick={() => onClickMenu(item)}
                            key={item.path}>
                            {item.name}
                        </MenuItem>
                    ))}
                </SubMenu>
            ))}
        </Menu>
    )
}

export default MenuList;