/*
 * @Author: yangzonglong
 * @Date: 2019-08-14 13:30:20
 * @version: v1.0.0
 * @Descripttion: 
 * @LastEditors: LEO
 * @LastEditTime: 2020-09-28 09:27:43
 * @Auditor: 
 */
import { renderRoutes } from 'react-router-config';
import { demoRouter } from '@features/demo';
import { mainFrameRoter } from '@features/mainFrame';
import { userRouter } from '@features/user';
import { agreementRouter } from '@features/agreement'

const routers = [
    ...demoRouter,
    ...mainFrameRoter,
    ...agreementRouter,
    ...userRouter
]

export const baseRouters = routers;
export default renderRoutes(routers)
