/*
 * @Author: yangzonglong
 * @Date: 2019-10-30 09:46:03
 * @version: v1.0.0
 * @Descripttion: 
 * @LastEditors  : yangzonglong
 * @LastEditTime : 2020-01-03 17:01:56
 * @Auditor: 
 */
import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { PACKAGE_NAME } from '@package';

const LOADPATH = '/locales/{{lng}}/{{ns}}.json';

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'zh',
    ns: ['error', 'menus', 'route', 'translation'],
    debug: process.env.REACT_APP_ENV === 'dev',
    backend: {
      loadPath: process.env.REACT_APP_ENV === 'dev' ? LOADPATH : `/${PACKAGE_NAME}${LOADPATH}`
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;