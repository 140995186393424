/*
 * @Author: yangzonglong
 * @Date: 2019-10-09 15:21:21
 * @version: v1.0.0
 * @Descripttion: welink平台特定样式layout
 * @LastEditors: yangzonglong
 * @LastEditTime: 2020-09-16 10:51:14
 * @Auditor: 
 */
import React, { useMemo, useState, useCallback, useEffect, createContext } from 'react';
import QueryString from 'query-string';
import { ConfigProvider } from 'antd';
import cls from './welinkinlayout.module.scss';
import { History } from 'history';
import Login from './Login';
import Icon from './../icon/Icon';
import Menu from './Menu';
import { initPageAuth, getPageAuth } from './../units/getPageAuth';
import locale from 'antd/es/locale/zh_CN';
import './../base.scss';
import './../resetAntd.scss';

export interface MenuProps {
    path: string, // 路径
    name: string, // 路由名称
    hide?: boolean // 是否隐藏
}

export interface LayoutProps {
    appId: string, // 应用标识
    appName?: string, // 应用名称
    dev?: boolean, // 开发模式 不去验证权限
    menus: Array<MenuProps>,
    history: History, // 路由对象
    hideLayout?: boolean,
    noPathAuth?: string[]
}

// filter current app menu
export function findAppMenus(appId: string, roleData: Array<any>) {
    let curAppMenus = roleData.filter((item: any) => item.moduleCode === appId); // 查找出所属当前应用的
    curAppMenus = curAppMenus && curAppMenus.length ? (curAppMenus[0].menu || []) : [];
    return curAppMenus.map((group: any) => {
        return {
            ...group,
            children: group.children.map((item: any) => ({
                name: item.functionName, path: '/' + item.functionUrl.split('.')[1]
            }))
        }
    })
}

const query = QueryString.parseUrl(window.location.href).query,
    userInfoStr = window.sessionStorage.getItem('userInfo'),
    userInfoObj = userInfoStr ? JSON.parse(userInfoStr) : { menuList: [] },
    roleData = userInfoObj.menuList;

export const LayoutContext = createContext({});

const Layout: React.FC<LayoutProps> = (props) => {

    const [isOpenMenu, setIsOpenMenu] = useState(true);
    const switchOpenHideMenu = useCallback(() => setIsOpenMenu(!isOpenMenu), [isOpenMenu]);

    const [hideSider, setHideSider] = useState(false);

    const [isLogin, setIsLogin] = useState(false);
    const cancelLogin = useCallback(() => setIsLogin(false), []);

    const appMenus = useMemo(() => {
        if (props.dev) {
            const developments: MenuProps[] = [], demos: MenuProps[] = [];
            props.menus.forEach(item => {
                if(item.hide) return;
                if(item.path.indexOf('demo') >= 0) demos.push(item);
                else developments.push(item);
            })
            return [
                { functionGroupDesc: 'Demo', iconb: '\ue6bd', children: demos },
                { functionGroupDesc: 'Development', iconb: '\ue733', children: developments }
            ]
        }
        return findAppMenus(props.appId, roleData);
    }, [props.menus, props.appId, props.dev]);

    const provideApis = useMemo(() => ({
        hideSider: () => setHideSider(true),
        showSider: () => setHideSider(false),
        getPageAuth: () => getPageAuth(`/${props.appId}${props.history.location.pathname}`) || {},
        getUserInfo: () => {
            const userInfoStr = window.sessionStorage.getItem('userInfo');
            if (!userInfoStr) {
                if (process.env.REACT_APP_ENV !== 'dev') {
                    window.location.href = '/navbarx/user/login';
                    return { Tenant: {} }
                };
                return { Tenant: {} };
            };
            return JSON.parse(userInfoStr);
        }
    }), [props]);

    useEffect(() => {
        //  原因：navBar 跳转时路由 project?fc=router 需转成项目自己的路由
        if (query && query.fc) props.history.push(query.fc as string)
    }, [props]);

    useEffect(() => {
        if (!userInfoStr && process.env.REACT_APP_ENV === 'dev') {
            if((props.noPathAuth || []).indexOf(props.history.location.pathname) < 0) setIsLogin(true);
        }
    }, [props.history, props.noPathAuth]);

    useEffect(() => initPageAuth(props.appId), [props.appId]);

    return (
        <ConfigProvider locale={locale} autoInsertSpaceInButton={false}>
            <div className={cls.wrap}>
                {!hideSider && !props.hideLayout ? (
                    <div className={`${cls.sider} ${!isOpenMenu ? cls.closesider : null}`}>
                        <h1 className={cls.appname}>{props.appName}</h1>
                        <Menu defaultSelectedKeys={['/' + query.fc as string]} history={props.history} menus={appMenus} />
                        <p className={`${cls.shrink} ${!isOpenMenu ? cls.shrinkhide : null}`}
                            onClick={switchOpenHideMenu}>
                            <Icon type='success' icon='&#xe614;' />
                        </p>
                    </div>
                ) : null}
                <div className={cls.main}>
                    <LayoutContext.Provider value={provideApis}>
                        {props.children}
                    </LayoutContext.Provider>
                </div>
                <Login onCancel={cancelLogin} visible={isLogin} />
            </div>
        </ConfigProvider>
    )
}

export default Layout;