/*
 * @Author: yangzonglong
 * @Date: 2019-08-22 17:24:31
 * @version: v1.0.0
 * @Descripttion: 
 * @LastEditors: yangzonglong
 * @LastEditTime: 2019-08-23 11:18:02
 * @Auditor: 
 */
import {createStore, applyMiddleware, combineReducers} from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise';
import logger from 'redux-logger';
import reducers from '@configs/reducers';

const enhance = store => dispatch => action => {
    if(action && action.type) dispatch(action)
}

export default createStore(
    combineReducers(reducers), 
    applyMiddleware(thunk,promise,enhance,logger)
)