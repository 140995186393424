/*
 * @Author: yangzonglong
 * @Date: 2019-11-18 17:30:11
 * @version: v1.0.0
 * @Descripttion: 
 * @LastEditors: yangzonglong
 * @LastEditTime: 2019-12-17 17:08:59
 * @Auditor: 
 */
import { lazy } from 'react';
export { default as mainFrame } from './redux/reducer';

const MainFrame = lazy(() => import('./MainFrame'));

export const mainFrameRoter = [
    { name: 'main', path: '/main', exact: true, component: MainFrame },
    { name: 'main', path: '/', component: MainFrame, exact: true }
]
