/*
 * @Author: yangzonglong
 * @Date: 2019-10-11 15:00:43
 * @version: v1.0.0
 * @Descripttion: 
 * @LastEditors: yangzonglong
 * @LastEditTime: 2019-11-11 09:00:32
 * @Auditor: 
 */
import { createBrowserHistory } from 'history';
import { PACKAGE_NAME } from '@package';

export default createBrowserHistory({
    basename: PACKAGE_NAME
})