import { lazy } from 'react'

export const userRouter = [{
  name: '用户',
  path: '/user',
  component: lazy(() => import('./RouteTransform')),
  children: [{
    name: '登录',
    path: '/user/login',
    component: lazy(() => import('./login/Login'))
  }, {
    name: '注册',
    path: '/user/signup',
    component: lazy(() => import('./signUp/SignUp'))
  }, {
    name: '重置密码',
    path: '/user/resetpw',
    component: lazy(() => import('./resetpw/ResetPw'))
  }]
}]
