import { PageAuthProps } from './../types';

const userInfoStr = window.sessionStorage.getItem('userInfo'),
    userInfo = userInfoStr ? JSON.parse(userInfoStr) : {},
    menuList = userInfo.menuList || [],
    pageAuthObj: { [prop: string]: PageAuthProps } = {};

export function initPageAuth(moduleCode: string) {
    const filter = menuList.filter((item: any) => item.moduleCode === moduleCode),
        currentModule = filter.length ? filter[0] : {},
        currentMenus = currentModule.menu || [];
    currentMenus.forEach((group: any) => {
        group.children.forEach((item: any) => {
            const companyFunctionRole = item.CompanyFunctionRole || {};
            const functionUrl = item.functionUrl.replace('.', '/');
            pageAuthObj['/' + functionUrl] = {
                create: companyFunctionRole.create,
                edit: companyFunctionRole.edit,
                delete: companyFunctionRole.delete
            }
        })
    })
}

export function getPageAuth(path: string) {
    return pageAuthObj[path]
}