/*
 * @Author: yangzonglong
 * @Date: 2019-09-03 15:41:07
 * @version: v1.0.0
 * @Descripttion: 开发环境下模似登录
 * @LastEditors: yangzonglong
 * @LastEditTime: 2019-12-13 16:08:48
 * @Auditor: 
 */
import React, { memo, useCallback, useState } from 'react';
import { Form, Input, message, Modal } from 'antd';
import server from './../units/server';
import md5 from 'md5';
import { FormComponentProps } from 'antd/lib/form/Form';

export interface loginProps extends FormComponentProps {
    visible: boolean,
    onCancel: () => void
}

const FormItem = Form.Item;
const Login: React.FC<loginProps> = memo((props: loginProps) => {

    const { getFieldDecorator, validateFields } = props.form;
    const [isCode, setIsVode] = useState(false);
    const [codeTime, setCodeTime] = useState(1);
    const getCode = useCallback(() => setCodeTime(codeTime+1), [codeTime]);
    
    const onOk = useCallback(() => {
        validateFields(async (err, values) => {
            if (err) return;
            values.password = md5(values.password);
            try {
                const result = await server('/bk/login', values);
                message.success('登录成功！可以进行开发了！');
                props.onCancel();
                window.sessionStorage.setItem('roleData','[]');
                window.sessionStorage.setItem('userInfo', JSON.stringify(result))
            } catch (error) {
                if (error.message === 'vCode required') return setIsVode(true);
                message.error('登录失败')   
            }
        })
    }, [validateFields,props]);

    return (
        <Modal
            okText='登录'
            visible={props.visible}
            onOk={onOk}
            onCancel={props.onCancel}
            title='模似登录'>
            <Form>
                <FormItem label='用户名'>
                    {getFieldDecorator('user')(<Input />)}
                </FormItem>
                <FormItem label='密码'>
                    {getFieldDecorator('password')(<Input />)}
                </FormItem>
                {isCode ? (
                    <FormItem label='验证码'>
                        {getFieldDecorator('vCode')(
                            <Input addonAfter={
                                <img onClick={getCode} alt='验证码' src={`/bk/getSvj1?${codeTime}`}/>
                            } />
                        )}
                    </FormItem>
                ) : null}
            </Form>
        </Modal>
    )
})

export default Form.create<loginProps>()(Login);