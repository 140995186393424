/*
 * @Author: yangzonglong
 * @Date: 2019-08-27 13:41:52
 * @version: v1.0.0
 * @Descripttion: 统一封装axios
 * @LastEditors: yangzonglong
 * @LastEditTime: 2019-12-15 10:54:58
 * @Auditor: 
 */
import axios, { AxiosRequestConfig } from 'axios';

const TIMEOUT = 1000 * 60 * 2;

function mergeParams(loading?: Function, options?: AxiosRequestConfig):[Function | undefined, AxiosRequestConfig]{
    const isLoading = typeof loading === 'string' || typeof loading === 'function';
    let correctLoading = isLoading ? loading : undefined,
        correcOptions = options || (isLoading ? {} : (options || {}));
    return [correctLoading, correcOptions]
}

function setDefaultOptions(correcOptions: AxiosRequestConfig, data: object, url: string){
    if(!correcOptions.method) correcOptions.method = 'POST';
    if(!correcOptions.timeout) correcOptions.timeout = TIMEOUT;
    correcOptions[correcOptions.method === 'GET' ? 'params' : 'data'] = data;
    correcOptions.url = url;
}

function controlLoading(loading: Function){
    loading(true);
    return () => loading(false);
}

export default async function (url: string, data: any, loading?: Function, options?: any) {

    const [correctLoading, correcOptions] = mergeParams(loading, options);

    setDefaultOptions(correcOptions, data, url);

    let closeLoading;
    if(correctLoading) closeLoading = controlLoading(correctLoading);

    try {
        const result = await axios(correcOptions);
        if (result.status === 200) {
            const data = result.data;
            if (data.status === 200) {
                if(closeLoading) closeLoading();
                return data.result;
            }
            // eslint-disable-next-line
            throw { message: data.message ? data.message.message : '' }
        }
        // eslint-disable-next-line
        throw { message: result.statusText }
    } catch (error) {
        if(closeLoading) closeLoading();
        throw error
    }
}